<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card elevation="0">
      <v-card-actions>
        <v-card-title>{{ formTitle }}</v-card-title>
        <v-spacer></v-spacer>
        <v-btn :to="{ name: 'SalesPlanMeasures' }" color="green" rounded>
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn :disabled="!valid" color="primary" rounded @click="save">
          {{ $t("Save") }}
        </v-btn>
      </v-card-actions>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="item.name"
                :label="$t('Name')"
                :rules="rules.name"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="salesPlanMeasureTypes"
                item-text="name"
                item-value="value"
                v-model="item.type"
                :label="$t('Type')"
                :rules="rules.type"
                required
              >
                <template v-slot:item="{ item }">
                  {{ $t(item.name) }}
                </template>
              </v-select>
            </v-col>

            <v-col v-if="item.type == 2" cols="12" sm="6" md="6">
              <v-select
                :items="activityTypes"
                item-text="name"
                item-value="id"
                v-model="item.activityTypeId"
                :label="$t('Types of Activity')"
                :rules="rules.activityTypeId"
                required
              ></v-select>
            </v-col>

            <v-col
              v-if="item.type == 0 || item.type == 1"
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                :items="filteredBaseNumbers"
                item-text="name"
                item-value="value"
                v-model="item.baseNumber"
                :label="$t('Base Number')"
                :rules="rules.baseNumber"
                required
              >
                <template v-slot:item="{ item }">
                  {{ $t(item.name) }}
                </template>
              </v-select>
            </v-col>

            <v-col
              v-if="item.baseNumber != null || item.type == 2"
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                :items="filteredCalculations"
                item-text="name"
                item-value="value"
                v-model="item.calculation"
                :label="$t('Calculation')"
                :rules="rules.calculation"
                required
              >
                <template v-slot:item="{ item }">
                  {{ $t(item.name) }}
                </template>
              </v-select>
            </v-col>

            <v-col v-if="item.baseNumber == 3" cols="12" sm="6" md="6">
              <v-select
                :items="customFields"
                item-text="name"
                item-value="id"
                v-model="item.customFieldId"
                :label="$t('Custom properties')"
                :rules="rules.customFieldId"
                required
                clearable
              ></v-select>
            </v-col>

            <v-col
              v-if="
                (item.type == 0 || item.type == 1) &&
                (item.baseNumber == 0 || item.baseNumber == 1)
              "
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                :items="solutionTypes"
                item-text="name"
                item-value="id"
                v-model="item.solutionTypeId"
                :label="$t('Solution type')"
                :rules="rules.solutionTypeId"
                required
                clearable
              ></v-select>
            </v-col>

            <v-col
              v-if="
                (item.type == 0 || item.type == 1) &&
                !(
                  item.baseNumber == 2 &&
                  (item.calculation == 4 ||
                    item.calculation == 5 ||
                    item.calculation == 6)
                )
              "
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                :items="clientTypes"
                item-text="name"
                item-value="id"
                v-model="item.clientTypeId"
                :label="$t('Client type')"
                :rules="rules.clientTypeId"
                required
                clearable
              ></v-select>
            </v-col>

            <v-col
              v-if="
                (item.type == 0 || item.type == 1) &&
                (item.baseNumber == 0 || item.baseNumber == 1) &&
                !item.calculation == 4
              "
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                :items="DealsProperties"
                item-text="name"
                item-value="value"
                v-model="item.dealsProperty"
                :label="$t('Deal properties')"
                :rules="rules.dealsProperty"
                required
                clearable
              >
                <template v-slot:item="{ item }">
                  {{ $t(item.name) }}
                </template>
              </v-select>
            </v-col>

            <v-col
              v-if="
                (item.type == 0 || item.type == 1) &&
                !(
                  item.baseNumber == 2 &&
                  (item.calculation == 4 ||
                    item.calculation == 5 ||
                    item.calculation == 6)
                )
              "
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                :items="RelationsProperties"
                item-text="name"
                item-value="value"
                v-model="item.relationsProperty"
                :label="$t('Relation properties')"
                :rules="rules.relationsProperty"
                required
                clearable
              >
                <template v-slot:item="{ item }">
                  {{ $t(item.name) }}
                </template>
              </v-select>
            </v-col>

            <v-col
              v-if="item.baseNumber == 2 && item.calculation == 6"
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                :items="RelationsProperties"
                item-text="name"
                item-value="value"
                v-model="item.relationsPropertyFrom"
                :label="$t('From')"
                :rules="rules.relationsProperty"
                required
                clearable
              >
                <template v-slot:item="{ item }">
                  {{ $t(item.name) }}
                </template>
              </v-select>
            </v-col>

            <v-col
              v-if="item.baseNumber == 2 && item.calculation == 6"
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                :items="RelationsProperties"
                item-text="name"
                item-value="value"
                v-model="item.relationsPropertyTo"
                :label="$t('To')"
                :rules="rules.relationsProperty"
                required
                clearable
              >
                <template v-slot:item="{ item }">
                  {{ $t(item.name) }}
                </template>
              </v-select>
            </v-col>

            <v-col
              v-if="item.baseNumber == 2 && item.calculation == 5"
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                :items="clientTypes"
                item-text="name"
                item-value="id"
                v-model="item.clientTypeIdFrom"
                :label="$t('From')"
                :rules="rules.clientTypeId"
                required
                clearable
              ></v-select>
            </v-col>

            <v-col
              v-if="item.baseNumber == 2 && item.calculation == 5"
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                :items="clientTypes"
                item-text="name"
                item-value="id"
                v-model="item.clientTypeIdTo"
                :label="$t('To')"
                :rules="rules.clientTypeId"
                required
                clearable
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-checkbox
                v-model="item.allowsUserDistribution"
                :label="$t('Allow Distribution')"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      item: {},
      salesPlanMeasureTypes: [],
      activityTypes: [],
      baseNumbers: [],
      customFields: [],
      calculations: [],
      calculationSettings: [],
      solutionTypes: [],
      clientTypes: [],
      DealsProperties: [],
      RelationsProperties: [],
      id: "",
      loading: false,
      rules: {
        name: [
          (value) => !!value || this.$t("First Name is Required"),
          (value) =>
            (value || "").length <= 100 || this.$t("Max 100 characters"),
        ],
        type: [(value) => !!value || value == 0 || this.$t("Type is Required")],
        valueType: [
          (value) => !!value || value == 0 || this.$t("Value Type is Required"),
        ],
      },
    };
  },

  computed: {
    formTitle() {
      return this.id == ""
        ? this.$t("Add Sales Plan Measure")
        : this.$t("Edit Sales Plan Measure");
    },
    filteredCalculations() {
      var fCalculations = [];

      if (this.calculationSettings.length == 0) {
        fCalculations = this.calculations;
      } else if (this.item.type == 2) {
        this.calculationSettings.forEach((calculationSetting) => {
          if (calculationSetting.activeForActivitySalesPlanMeasures) {
            fCalculations.push(
              this.calculations.find(
                (c) => c.value == calculationSetting.calculation
              )
            );
          }
        });
      } else if (this.item.baseNumber != null) {
        this.calculationSettings.forEach((calculationSetting) => {
          if (
            calculationSetting.activeForBaseNumbersList.find(
              (a) => a == this.item.baseNumber
            ) != undefined
          ) {
            fCalculations.push(
              this.calculations.find(
                (c) => c.value == calculationSetting.calculation
              )
            );
          }
        });
      } else {
        fCalculations = this.calculations;
      }

      //Exception: 
      //do not show growth calculation if custom field linked to relations is selected
      if(this.item.baseNumber == 3 && this.item.customFieldId != null)
      {
        var currentCustomField = this.customFields.find((c) => c.id == this.item.customFieldId)
        if(currentCustomField != undefined && currentCustomField.isRelationCustomField){
          fCalculations = fCalculations.filter((c) => c.value != 1)
        }
      }

      return fCalculations.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
    filteredBaseNumbers() {
      var fBaseNumbers = this.baseNumbers;

      return fBaseNumbers.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
  },
  created: function () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getItem();
    }

    this.fetchSalesPlanMeasureTypes();
    this.fetchActivityTypes();
    this.fetchBaseNumbers();
    this.fetchCustomFields();
    this.fetchCalculations();
    this.fetchCalculationSettings();
    this.fetchSolutionTypes();
    this.fetchClientTypes();
    this.fetchDealsProperties();
    this.fetchRelationsProperties();
  },

  methods: {
    fetchActivityTypes(update = null) {
      let uri = this.$urlPrefix + "/ActivityType/CountAll";
      this.axios.get(uri).then((response) => {
        let uri2 =
          this.$urlPrefix +
          "/ActivityType/GetList?start=0&count=" +
          response.data;
        this.axios.get(uri2).then((response) => {
          this.activityTypes = response.data;
          this.activityTypes.forEach((activityType) => {
            this.activities[activityType.id] = {
              activityTypeId: activityType.id,
              amount: 0,
            };
          });
          if (update) {
            this.getItem();
          }
          this.loading = false;
        });
      });
    },

    fetchSalesPlanMeasureTypes() {
      let uri2 = this.$urlPrefix + "/SalesPlanMeasureType/GetAll";
      this.axios.get(uri2).then((response) => {
        this.salesPlanMeasureTypes = response.data;
        this.loading = false;
      });
    },

    fetchBaseNumbers() {
      let uri2 = this.$urlPrefix + "/SalesPlanMeasureBaseNumber/GetAll";
      this.axios.get(uri2).then((response) => {
        this.baseNumbers = response.data;
        this.loading = false;
      });
    },

    fetchCustomFields() {
      let uri2 =
        this.$urlPrefix + "/SalesPlanMeasureBaseNumber/GetCustomFields";
      this.axios.get(uri2).then((response) => {
        this.customFields = response.data;
        this.loading = false;
      });
    },

    fetchCalculations() {
      let uri2 = this.$urlPrefix + "/SalesPlanMeasureCalculation/GetAll";
      this.axios.get(uri2).then((response) => {
        this.calculations = response.data;
        this.loading = false;
      });
    },

    fetchCalculationSettings() {
      let uri2 = this.$urlPrefix + "/SalesPlanMeasureCalculation/GetSettings";
      this.axios.get(uri2).then((response) => {
        this.calculationSettings = response.data;
        this.loading = false;
      });
    },

    fetchSolutionTypes() {
      let uri = this.$urlPrefix + "/SolutionType/CountAll";
      this.axios.get(uri).then((response) => {
        let uri2 =
          this.$urlPrefix +
          "/SolutionType/GetList?start=0&count=" +
          response.data;
        this.axios.get(uri2).then((response) => {
          this.solutionTypes = response.data;
          this.loading = false;
        });
      });
    },

    fetchClientTypes() {
      let uri = this.$urlPrefix + "/ClientType/CountAll";
      this.axios.get(uri).then((response) => {
        let uri2 =
          this.$urlPrefix +
          "/ClientType/GetList?start=0&count=" +
          response.data;
        this.axios.get(uri2).then((response) => {
          this.clientTypes = response.data;
          this.loading = false;
        });
      });
    },

    fetchDealsProperties() {
      let uri2 = this.$urlPrefix + "/SalesPlanMeasureDealsProperty/GetAll";
      this.axios.get(uri2).then((response) => {
        this.DealsProperties = response.data;
        this.loading = false;
      });
    },

    fetchRelationsProperties() {
      let uri2 = this.$urlPrefix + "/SalesPlanMeasureRelationsProperty/GetAll";
      this.axios.get(uri2).then((response) => {
        this.RelationsProperties = response.data;
        this.loading = false;
      });
    },

    getItem() {
      this.loading = true;
      let uri = this.$urlPrefix + "/SalesPlanMeasure/Get?id=" + this.id;
      this.axios.get(uri).then((response) => {
        this.item = response.data;
        this.loading = false;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.id != "") {
          let uri = this.$urlPrefix + "/SalesPlanMeasure/Update/";
          this.axios.put(uri, this.item).then((response) => {
            if (response.status == 204 || response.status == 200) {
              this.loading = false;
              this.$router.push({ name: "SalesPlanMeasures" });
            }
          });
        } else {
          let uri = this.$urlPrefix + "/SalesPlanMeasure/Add";
          this.axios.post(uri, this.item).then((response) => {
            if (response.status == 200) {
              this.item = {};
              this.loading = false;
              this.$router.push({ name: "SalesPlanMeasures" });
            }
          });
        }
      }
    },
  },
};
</script>
