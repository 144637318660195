var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-actions',[_c('v-card-title',[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'SalesPlanMeasures' },"color":"green","rounded":""}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"primary","rounded":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Name'),"rules":_vm.rules.name,"required":""},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.salesPlanMeasureTypes,"item-text":"name","item-value":"value","label":_vm.$t('Type'),"rules":_vm.rules.type,"required":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}}]),model:{value:(_vm.item.type),callback:function ($$v) {_vm.$set(_vm.item, "type", $$v)},expression:"item.type"}})],1),(_vm.item.type == 2)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.activityTypes,"item-text":"name","item-value":"id","label":_vm.$t('Types of Activity'),"rules":_vm.rules.activityTypeId,"required":""},model:{value:(_vm.item.activityTypeId),callback:function ($$v) {_vm.$set(_vm.item, "activityTypeId", $$v)},expression:"item.activityTypeId"}})],1):_vm._e(),(_vm.item.type == 0 || _vm.item.type == 1)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.filteredBaseNumbers,"item-text":"name","item-value":"value","label":_vm.$t('Base Number'),"rules":_vm.rules.baseNumber,"required":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}}],null,false,60565143),model:{value:(_vm.item.baseNumber),callback:function ($$v) {_vm.$set(_vm.item, "baseNumber", $$v)},expression:"item.baseNumber"}})],1):_vm._e(),(_vm.item.baseNumber != null || _vm.item.type == 2)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.filteredCalculations,"item-text":"name","item-value":"value","label":_vm.$t('Calculation'),"rules":_vm.rules.calculation,"required":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}}],null,false,60565143),model:{value:(_vm.item.calculation),callback:function ($$v) {_vm.$set(_vm.item, "calculation", $$v)},expression:"item.calculation"}})],1):_vm._e(),(_vm.item.baseNumber == 3)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.customFields,"item-text":"name","item-value":"id","label":_vm.$t('Custom properties'),"rules":_vm.rules.customFieldId,"required":"","clearable":""},model:{value:(_vm.item.customFieldId),callback:function ($$v) {_vm.$set(_vm.item, "customFieldId", $$v)},expression:"item.customFieldId"}})],1):_vm._e(),(
              (_vm.item.type == 0 || _vm.item.type == 1) &&
              (_vm.item.baseNumber == 0 || _vm.item.baseNumber == 1)
            )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.solutionTypes,"item-text":"name","item-value":"id","label":_vm.$t('Solution type'),"rules":_vm.rules.solutionTypeId,"required":"","clearable":""},model:{value:(_vm.item.solutionTypeId),callback:function ($$v) {_vm.$set(_vm.item, "solutionTypeId", $$v)},expression:"item.solutionTypeId"}})],1):_vm._e(),(
              (_vm.item.type == 0 || _vm.item.type == 1) &&
              !(
                _vm.item.baseNumber == 2 &&
                (_vm.item.calculation == 4 ||
                  _vm.item.calculation == 5 ||
                  _vm.item.calculation == 6)
              )
            )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.clientTypes,"item-text":"name","item-value":"id","label":_vm.$t('Client type'),"rules":_vm.rules.clientTypeId,"required":"","clearable":""},model:{value:(_vm.item.clientTypeId),callback:function ($$v) {_vm.$set(_vm.item, "clientTypeId", $$v)},expression:"item.clientTypeId"}})],1):_vm._e(),(
              (_vm.item.type == 0 || _vm.item.type == 1) &&
              (_vm.item.baseNumber == 0 || _vm.item.baseNumber == 1) &&
              !_vm.item.calculation == 4
            )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.DealsProperties,"item-text":"name","item-value":"value","label":_vm.$t('Deal properties'),"rules":_vm.rules.dealsProperty,"required":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}}],null,false,60565143),model:{value:(_vm.item.dealsProperty),callback:function ($$v) {_vm.$set(_vm.item, "dealsProperty", $$v)},expression:"item.dealsProperty"}})],1):_vm._e(),(
              (_vm.item.type == 0 || _vm.item.type == 1) &&
              !(
                _vm.item.baseNumber == 2 &&
                (_vm.item.calculation == 4 ||
                  _vm.item.calculation == 5 ||
                  _vm.item.calculation == 6)
              )
            )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.RelationsProperties,"item-text":"name","item-value":"value","label":_vm.$t('Relation properties'),"rules":_vm.rules.relationsProperty,"required":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}}],null,false,60565143),model:{value:(_vm.item.relationsProperty),callback:function ($$v) {_vm.$set(_vm.item, "relationsProperty", $$v)},expression:"item.relationsProperty"}})],1):_vm._e(),(_vm.item.baseNumber == 2 && _vm.item.calculation == 6)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.RelationsProperties,"item-text":"name","item-value":"value","label":_vm.$t('From'),"rules":_vm.rules.relationsProperty,"required":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}}],null,false,60565143),model:{value:(_vm.item.relationsPropertyFrom),callback:function ($$v) {_vm.$set(_vm.item, "relationsPropertyFrom", $$v)},expression:"item.relationsPropertyFrom"}})],1):_vm._e(),(_vm.item.baseNumber == 2 && _vm.item.calculation == 6)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.RelationsProperties,"item-text":"name","item-value":"value","label":_vm.$t('To'),"rules":_vm.rules.relationsProperty,"required":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}}],null,false,60565143),model:{value:(_vm.item.relationsPropertyTo),callback:function ($$v) {_vm.$set(_vm.item, "relationsPropertyTo", $$v)},expression:"item.relationsPropertyTo"}})],1):_vm._e(),(_vm.item.baseNumber == 2 && _vm.item.calculation == 5)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.clientTypes,"item-text":"name","item-value":"id","label":_vm.$t('From'),"rules":_vm.rules.clientTypeId,"required":"","clearable":""},model:{value:(_vm.item.clientTypeIdFrom),callback:function ($$v) {_vm.$set(_vm.item, "clientTypeIdFrom", $$v)},expression:"item.clientTypeIdFrom"}})],1):_vm._e(),(_vm.item.baseNumber == 2 && _vm.item.calculation == 5)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.clientTypes,"item-text":"name","item-value":"id","label":_vm.$t('To'),"rules":_vm.rules.clientTypeId,"required":"","clearable":""},model:{value:(_vm.item.clientTypeIdTo),callback:function ($$v) {_vm.$set(_vm.item, "clientTypeIdTo", $$v)},expression:"item.clientTypeIdTo"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('Allow Distribution')},model:{value:(_vm.item.allowsUserDistribution),callback:function ($$v) {_vm.$set(_vm.item, "allowsUserDistribution", $$v)},expression:"item.allowsUserDistribution"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }